.main-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main-content {
  flex: 1 0 auto;
  padding-bottom: 0;
}

body,
html {
  height: 100%;
}

.body-help {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  color: #000;
  background-color: #fff;
  cursor: default;
}

a {
  &:focus,
  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

.container {
  margin: 0 auto;
  padding: 0 16px;
  width: 100%;
  max-width: 918px;
}

.container--footer {
  max-width: 1328px;
}

.looking {
  padding: 72px 0;
  background-color: #edf3ff;

  h2 {
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    text-align: center;

    @include m($lg) {
      font-size: 26px;
      line-height: 32px;
    }

    @include m($md) {
      font-size: 20px;
      line-height: 30px;
    }
  }

  &__box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;

    p,
    a {
      font-size: 18px;
      line-height: 30px;
      text-align: center;
      color: #97a4ba;

      @include m($lg) {
        font-size: 16px;
        line-height: 24px;
      }

      @include m($md) {
        font-size: 14px;
        line-height: 20px;
      }
    }

    a {
      margin-left: 6px;
      color: #00e1d4;

      @include m($md) {
        margin-left: 5px;
      }
    }
  }
}

.rv-button {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 0 24px;
  width: fit-content;
  min-height: 50px;
  overflow: hidden;
  border-radius: 12px;
  background-image: linear-gradient(225deg, #a9ffe0 39.58%, #86d5ff 100%);

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    visibility: hidden;
    opacity: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)), linear-gradient(225deg, #a9ffe0 39.58%, #86d5ff 100%);
    transition: all 0.3s;
  }

  span {
    z-index: 5;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  &:hover::after {
    visibility: visible;
    opacity: 1;
  }
}

.result {
  margin: -117px auto 72px !important;

  h3 {
    margin-top: 32px;
    margin-bottom: 16px !important;

    &:first-of-type {
      margin-top: 0;
    }

    @include m($lg) {
      margin-top: 24px;
    }
  }

  ul {
    li {
      a::after {
        display: none;
      }
    }
  }

  @include m($lg) {
    padding: 20px 24px !important;
  }
}

.rv-search {
  padding: 40px 0 53px;
  background-image: linear-gradient(234.96deg, #a9ffe0 16.38%, #86d5ff 100%);

  h2 {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    text-align: center;

    @include m($lg) {
      font-size: 26px;
    }

    @include m($md) {
      font-size: 24px;
    }
  }

  label {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 36px;
  }

  .button-search {
    position: absolute;
    left: 24px;
    top: 50%;
    padding: 0;
    width: 24px;
    height: 24px;
    border: none;
    background-color: transparent;
    transform: translateY(-50%);
    cursor: pointer;

    @include m($lg) {
      left: 22px;
    }

    @include m($md) {
      width: 20px;
      height: 20px;
    }
  }

  input {
    padding-left: 64px;
    width: 100%;
    height: 54px;
    outline: none;
    border: none;
    border-radius: 12px;
    box-shadow: 0 0 6px rgba(5, 173, 195, 0.26), 0 12px 10px -4px rgba(5, 173, 195, 0.16);
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: #000;
    background-color: #fff;

    &::placeholder {
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      color: #97a4ba;

      @include m($lg) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    @include m($lg) {
      padding-left: 60px;
      height: 49px;
      font-size: 16px;
      line-height: 24px;
    }

    @include m($md) {
      height: 44px;
    }
  }

  .bread {
    display: flex;

    li {
      + li {
        position: relative;
        margin-left: 16px;

        &::after {
          content: "/";
          position: absolute;
          left: -10px;
          top: 50%;
          color: rgba(#000, 0.7);
          transform: translateY(-50%);
        }
      }
    }

    a {
      opacity: 0.7;
      border-bottom: 1px solid rgba(#000, 0.7);
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.01em;
    }
  }

  @include m($lg) {
    padding: 40px 0 63px;
  }
}

.result-search {
  padding: 40px 0 93px;

  label {
    margin-bottom: 16px;
  }
}

.lines {
  height: 24px;
  background-image: linear-gradient(180deg, #fff 0%, #f6f9fc 100%);
}

.not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 38px;
  background-color: #fff;

  h2 {
    margin: 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #000;

    @include m($lg) {
      font-size: 18px;
      line-height: 28px;
    }

    @include m($md) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  @include m($lg) {
    height: 60px;
  }

  @include m($md) {
    height: 48px;
  }
}

#swiper-tag-box {
  margin: 0;

  .swiper {
    position: relative;
    height: 27px;

    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      z-index: 5;
      width: 90px;
      height: 27px;
      background-image: linear-gradient(270deg, #000 0%, rgba(0, 0, 0, 0) 100%);

      @include m($md) {
        width: 60px;
      }
    }

    .swiper-slide {
      z-index: 20;
      display: block;
    }

    .swiper-wrapper {
      position: relative;
      left: 0;
      margin-left: auto;
    }
  }
}

#swiper-cat {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    z-index: 5;
    width: 90px;
    height: 24px;
    background-image: linear-gradient(270deg, #000 0%, rgba(0, 0, 0, 0) 100%);

    @include m($md) {
      width: 60px;
    }
  }

  .swiper-wrapper {
    position: relative;
    left: 0;
    margin-left: auto;
  }

  .swiper-slide {
    z-index: 20;
    display: block;
    width: 24px;
    height: 24px;

    a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.12);
      transition: all 0.3s;

      &:hover {
        background-color: #343434;

        img {
          opacity: 1;
        }
      }
    }

    img {
      opacity: 0.3;
      width: auto;
      height: auto;
      transition: all 0.3s;
    }
  }
}

.box-slide {
  margin: 40px 0 24px;
  background-color: #000;
}

.panel-group {
  display: none;
  margin-bottom: 0 !important;
  width: 100%;
  color: #fff;
  background-color: transparent;

  .panel {
    background-color: transparent;
  }

  .panel-title {
    padding: 11px 0;

    .accordion-toggle {
      position: relative;
      margin: 0 auto;
      width: fit-content;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #fff;
      caret-color: transparent;

      &::after {
        content: url("../images/lang-arrow.svg");
        position: absolute;
        right: -26px;
        top: 38%;
        width: 24px;
        height: 24px;
        transform: scale(0.7) translateY(-50%) rotate(180deg);
        transition: all 0.3s;
      }
    }

    .collapsed {
      &::after {
        content: url("../images/lang-arrow.svg");
        position: absolute;
        right: -26px;
        top: 38%;
        width: 24px;
        height: 24px;
        transform: scale(0.7) translateY(-50%);
        transition: all 0.3s;
      }
    }
  }

  .panel-body {
    padding: 8px 0;
  }

  .panel-collapse {
    border-radius: 8px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.1);

    a {
      padding: 8px 0;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #fff;
    }
  }

  @include m($md) {
    display: block;
  }
}

.secret {
  display: flex;
  padding: 16px 24px 16px 26px;
  background: linear-gradient(0deg, rgba(255, 158, 104, 0.15), rgba(255, 158, 104, 0.15)), #000;

  p {
    margin-right: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #ffac7d;
  }

  .warn-brown {
    margin-right: 8px;
  }

  .close-brown {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 auto;
    padding: 0;
    width: 24px;
    height: 24px;
    min-width: 24px;
    max-width: 24px;
    outline: none;
    border: none;
    background-color: transparent;

    img {
      width: 24px;
      height: 24px;
      min-width: 24px;
      max-width: 24px;
    }
  }

  @include m($xl) {
    padding: 16px;
  }
}

.secret--close {
  display: none;
}

.shadow-slide-tag {
  visibility: hidden;
  opacity: 0;
}

@media screen and (max-width: 750px) {
  .menu-tag-btn:hover {
    color: rgba(255, 255, 255, 0.5) !important;
    background-color: #1e201b !important;
  }

  .menu-tag-btn-active {
    color: #000 !important;
    background-color: #fff !important;
  }

  .menu-tag-btn-active:hover {
    color: #000 !important;
    background-color: #fff !important;
  }
}