.promoted {
  padding: 72px 0;

  h4 {
    margin-bottom: 32px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    li {
      width: 100%;
      max-width: 46%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.03);

      a {
        position: relative;
        display: flex;
        align-items: center;
        padding: 16px 0 16px 24px;
        height: 100%;
        border-radius: 8px;
        font-size: 16px;
        line-height: 21px;
        color: #303030;
        transition: all 0.3s;

        &::after {
          content: url("../images/link.svg");
          position: absolute;
          left: 2px;
          top: 50%;
          transform: translateY(-50%);
        }

        &:hover {
          background-color: #edf3ff;
        }

        @include m($lg) {
          font-size: 15px;
          line-height: 18px;
        }

        @include m($md) {
          font-size: 14px;
          line-height: 17px;
        }
      }

      @include m(580) {
        max-width: 100%;
      }
    }

    @include m(580) {
      flex-direction: column;
    }
  }
}

.parts {
  position: relative;
  display: grid;
  grid-gap: 0 47px;
  grid-template-columns: 1fr 1fr 1fr;
  margin: -37px auto 0;
  padding: 16px;
  border: 1px solid #f6f9fc;
  border-radius: 14px;
  box-shadow: 0 17px 14px -10px rgba(0, 0, 0, 0.05);
  background-color: #fff;

  li {
    position: relative;

    a {
      display: block;
      padding: 12px 8px;
      width: 100%;
      border-radius: 14px;
      text-align: center;
      background-color: #fff;
      transition: all 0.3s;

      img {
        margin: 0 auto 16px;
      }

      span {
        display: block;
        margin-bottom: 8px;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #000;

        @include m($lg) {
          font-size: 18px;
          line-height: 22px;
        }
      }

      p {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        color: #97a4ba;
      }

      &:hover {
        background: #edf3ff;
      }

      @include m($md) {
        padding: 24px 8px;
        border-radius: 0;
      }
    }

    &:not(:last-of-type) {
      &::after {
        content: "";
        position: absolute;
        right: -24px;
        top: 50%;
        z-index: 0;
        width: 1px;
        height: 143px;
        background-color: rgba(0, 0, 0, 0.05);
        transform: translateY(-50%);

        @include m($lg) {
          right: -12px;
        }

        @include m($md) {
          display: none;
        }
      }

      @include m($md) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        border-radius: 0;
      }
    }

    &:first-of-type {
      a {
        @include m($md) {
          border-radius: 14px 14px 0 0;
        }
      }
    }

    &:last-of-type {
      a {
        @include m($md) {
          border-radius: 0 0 14px 14px;
        }
      }
    }
  }

  @include m($lg) {
    grid-gap: 0 23px;
    margin: -47px auto 0;
    padding: 12px;
  }

  @include m($md) {
    grid-template-columns: 1fr;
    padding: 0;
    overflow: hidden;
  }
}