.footer-help {
  flex: 0 0 auto;
  padding: 72px 0;
  color: #fff;
  background-color: #000;

  h3 {
    margin-bottom: 24px;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;

    @include m($lg) {
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 24px;
    }

    @include m($md) {
      margin-bottom: 16px;
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 104px;

    @include m($lg) {
      margin-bottom: 78px;
    }

    @include m($md) {
      flex-direction: column-reverse;
      margin-bottom: 48px;
    }
  }

  &__copy {
    p {
      font-size: 16px;
      line-height: 24px;
      color: #fff;

      @include m($lg) {
        font-size: 14px;
        line-height: 20px;
        color: #c4c4c4;
      }
    }
  }

  &__mail {
    p,
    a {
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      color: #a9ffe0;

      @include m($lg) {
        font-size: 22px;
      }

      @include m($md) {
        font-size: 20px;
        line-height: 30px;
      }
    }

    a {
      font-weight: 700;
    }

    @include m($md) {
      margin-bottom: 48px;
    }
  }

  ul {
    display: flex;

    li {
      &:not(:last-child) {
        margin-right: 189px;

        @include m(1500) {
          margin-right: 120px;
        }

        @include m($xl) {
          margin-right: 80px;
        }

        @include m($lg) {
          margin-right: 40px;
        }

        @include m($md) {
          margin-right: 0;
        }

        @include m($sm) {
          margin-bottom: 48px;
        }
      }

      a {
        width: fit-content;
        font-size: 16px;
        line-height: 24px;

        &:not(:last-child) {
          margin-bottom: 16px;
        }

        @include m($lg) {
          font-size: 14px;
          line-height: 20px;
        }
      }

      @include m($md) {
        width: 33%;
      }

      @include m($sm) {
        width: 100%;
      }
    }

    @include m($md) {
      justify-content: space-between;
    }

    @include m($sm) {
      flex-direction: column;
    }
  }
}