.header-help {
  z-index: 30;
  display: flex;
  align-items: center;
  width: 100%;
  height: 72px;
  background-color: #000;

  .container {
    width: 100%;
  }

  .menu {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      img {
        @include m($lg) {
          max-width: 88px;
        }

        @include m($md) {
          max-width: 68px;
        }
      }
    }

    &__burger {
      position: relative;
      display: none;
      align-items: center;
      padding: 0;
      width: 20px;
      height: 15px;
      border: none;
      background-color: transparent;
      cursor: pointer;

      img {
        position: absolute;
        left: 0;
        top: 0;
        transition: all 0.3s;

        &:first-of-type {
          visibility: visible;
          opacity: 1;
        }

        &:last-of-type {
          left: 50%;
          visibility: hidden;
          opacity: 0;
          width: 15px;
          height: 15px;
          transform: translateX(-50%);
        }
      }

      @include m($md) {
        display: flex;
      }
    }

    &__burger--active {
      img {
        &:first-of-type {
          visibility: hidden;
          opacity: 0;
        }

        &:last-of-type {
          left: 50%;
          visibility: visible;
          opacity: 1;
          width: 15px;
          height: 15px;
          transform: translateX(-50%);
        }
      }
    }

    &__items {
      display: flex;
      align-items: center;
      transition: all 0.3s;

      .menu__button,
      .menu__link {
        font-size: 16px;
        line-height: 19px;
        color: #fff;

        @include m($lg) {
          font-size: 14px;
        }

        @include m($md) {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
        }
      }

      .menu__link {
        display: flex;
        align-items: center;
        margin-right: 40px;

        @include m($lg) {
          margin-right: 28px;
        }

        @include m($md) {
          justify-content: center;
          margin-right: 0;
          padding: 11px 0;
          width: 100%;
          border-bottom: 1px solid rgba(255, 255, 255, 0.15);
        }
      }

      .menu__button {
        position: relative;
        display: flex;
        align-items: center;
        padding: 0 26px 0 0;
        height: 24px;
        border: none;
        background-color: transparent;
        cursor: pointer;

        &::after {
          content: url("../images/lang-arrow.svg");
          position: absolute;
          right: 0;
          top: 0;
          width: 24px;
          height: 24px;
          transition: all 0.3s;

          @include m($lg) {
            transform: scale(0.8);
          }

          @include m($md) {
            right: -3px;
            top: 45%;
            transform: scale(0.7) translateY(-50%);
          }
        }

        @include m($md) {
          display: none;
          justify-content: center;
          margin: 0 auto;
          padding: 0 22px 0 0;
          height: 47px;
          text-align: center;
        }
      }

      @include m($md) {
        position: absolute;
        left: 0;
        top: 72px;
        z-index: 5;
        flex-direction: column;
        padding: 0 16px 16px;
        width: 100%;
        background-color: #000;
        transform: translateX(-200%);
      }
    }

    &__items--active {
      transform: none;
    }

    &__items--lang {
      padding: 0 16px 140px;
    }

    &__lang {
      position: relative;

      @include m($md) {
        width: 100%;
      }
    }

    &__lang--rotate {
      .menu__button {
        &::after {
          transform: rotate(180deg);

          @include m($lg) {
            transform: scale(0.8) rotate(180deg);
          }

          @include m($md) {
            transform: scale(0.7) translateY(-50%) rotate(180deg);
          }
        }
      }
    }

    &__drop {
      position: absolute;
      right: 0;
      top: 36px;
      z-index: 20;
      visibility: hidden;
      opacity: 0;
      padding: 14px 16px;
      width: 127px;
      border: 1px solid #000;
      border-radius: 16px;
      background-color: #fff;
      transition: all 0.3s;

      li {
        &:not(:last-child) {
          margin-bottom: 8px;
          padding-bottom: 8px;
          border-bottom: 1px solid #c4c4c4;

          @include m($lg) {
            margin-bottom: 6px;
            padding-bottom: 6px;
          }
        }
      }

      a {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        transition: all 0.3s;

        &:hover {
          opacity: 0.7;
        }

        @include m($lg) {
          font-size: 14px;
          line-height: 18px;
        }
      }

      @include m($lg) {
        padding: 9px;
        width: 100px;
      }

      @include m($md) {
        display: none;
      }
    }

    &__drop--active {
      visibility: visible;
      opacity: 1;
    }
  }
}