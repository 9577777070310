.article-wrap {
  margin: -37px auto 72px;
  padding: 40px 48px;
  border: 1px solid #f6f9fc;
  border-radius: 14px;
  box-shadow: 0 17px 14px -10px rgba(0, 0, 0, 0.05);
  font-size: 16px;
  line-height: 24px;
  color: #303030;
  background-color: #fff;

  h2 {
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    color: #171717;

    @include m($lg) {
      font-size: 26px;
      line-height: 34px;
    }

    @include m($md) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  ul {
    margin-bottom: 16px;
    padding-left: 20px;

    li {
      position: relative;
      padding-left: 18px;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 10px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #000;
      }

      &:not(:last-child) {
        margin-bottom: 6px;
      }
    }
  }

  ol {
    margin-bottom: 16px;
    padding-left: 36px;

    li {
      &:not(:last-child) {
        margin-bottom: 6px;
      }
    }
  }

  img {
    margin: 0 auto 16px;
    object-fit: cover;
  }

  a {
    color: #00e1d4;
  }

  p {
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: #303030;

    a {
      display: inline;
      color: #00e1d4;
    }

    @include m($lg) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  @include m($lg) {
    font-size: 14px;
    line-height: 20px;
  }

  @include m($lg) {
    margin: -47px auto 72px;
    padding: 30px 36px;
  }

  @include m($md) {
    padding: 20px 24px;
  }
}