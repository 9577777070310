.category {
  margin: -37px auto 72px;
  padding: 24px;
  border: 1px solid #f6f9fc;
  border-radius: 14px;
  box-shadow: 0 17px 14px -10px rgba(0, 0, 0, 0.05);
  background-color: #fff;

  h3 {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  }

  &__text {
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #303030;
  }

  ul {
    li {
      h4 {
        margin-bottom: 16px;
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        text-transform: capitalize;

        @include m($lg) {
          font-size: 16px;
          line-height: 24px;
        }
      }

      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-transform: capitalize;
        color: #303030;

        @include m($lg) {
          font-size: 12px;
          line-height: 18px;
        }
      }

      a {
        position: relative;
        padding: 24px;
        background-color: #f7f8fa;
        transition: all 0.3s;

        &::after {
          content: url("../images/link-arrow.svg");
          position: absolute;
          right: 24px;
          top: 24px;
          width: 24px;
          height: 24px;

          @include m($lg) {
            right: 16px;
            top: 20px;
          }

          @include m($md) {
            display: none;
          }
        }

        &:hover {
          background-color: #edf3ff;
        }

        @include m($lg) {
          padding: 20px 16px;
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      }

      &:first-child {
        a {
          border-radius: 14px 14px 0 0;
        }
      }

      &:last-child {
        a {
          border-radius: 0 0 14px 14px;
        }
      }
    }
  }

  @include m($lg) {
    margin: -47px auto 72px;
  }
}